import { Injectable } from "@angular/core";
import { EnviromentService } from "./enviroment.service";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { Training } from "app/_models/Training";

@Injectable()
export class TrainingService {
  baseUrl = "";
  constructor(
    private http: HttpClient,
    private enviromentService: EnviromentService
  ) {
    this.baseUrl = enviromentService.getConfig().apiV3Url;
  }

  add(add: Training): Observable<number> {
    return this.http.post<number>(this.baseUrl + "trainings", add);
  }

  delete(id: number): Observable<boolean> {
    return this.http.delete<boolean>(this.baseUrl + "trainings/" + id);
  }

  update(update: Training): Observable<number> {
    return this.http.put<number>(
      this.baseUrl + "trainings/" + update.id,
      update
    );
  }

  get(id: number): Observable<Training> {
    return this.http.get<Training>(this.baseUrl + "/trainings/" + id);
  }

  getByCenterId(centerId: number): Observable<Training[]> {
    return this.http.get<Training[]>(
      this.baseUrl + "centers/" + centerId + "/trainings"
    );
  }
}
