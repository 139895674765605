import { MasterFranchiseDefault } from "./../../_models/mfa/MasterFranchiseDefault"

import { EnviromentService } from "./../enviroment.service"
import { Injectable } from "@angular/core"
import { HttpClient, HttpParams } from "@angular/common/http"
import { Observable } from "rxjs"
import { PaginatedResult } from "app/_models/Pagination"
import { map } from "rxjs/operators"

@Injectable()
export class MasterFranchiseDefaultService {
  baseUrl = ""

  constructor(
    private http: HttpClient,
    private enviromentService: EnviromentService
  ) {
    this.baseUrl = enviromentService.getConfig().apiMfa
  }

  add(add: MasterFranchiseDefault): Observable<number> {
    return this.http.post<number>(this.baseUrl + "MasterFranchiseDefaults", add)
  }

  delete(id: number): Observable<boolean> {
    return this.http.delete<boolean>(
      this.baseUrl + "MasterFranchiseDefaults/" + id
    )
  }

  update(update: MasterFranchiseDefault): Observable<number> {
    return this.http.put<number>(
      this.baseUrl + "MasterFranchiseDefaults/" + update.id,
      update
    )
  }

  get(id: number): Observable<MasterFranchiseDefault> {
    return this.http.get<MasterFranchiseDefault>(
      this.baseUrl + "MasterFranchiseDefaults/" + id
    )
  }

  getMasterFranchiseDefaults(
    page?,
    itemsPerPage?,
    MasterFranchiseDefaultParams?
  ): Observable<PaginatedResult<MasterFranchiseDefault[]>> {
    const paginatedResult: PaginatedResult<MasterFranchiseDefault[]> =
      new PaginatedResult<MasterFranchiseDefault[]>()

    let params = new HttpParams()

    if (page != null && itemsPerPage != null) {
      params = params.append("pageNumber", page)
      params = params.append("pageSize", itemsPerPage)
    }

    return this.http
      .get<MasterFranchiseDefault[]>(this.baseUrl + "MasterFranchiseDefaults", {
        observe: "response",
        params,
      })
      .pipe(
        map((response) => {
          paginatedResult.result = response.body
          if (response.headers.get("Pagination") != null) {
            paginatedResult.pagination = JSON.parse(
              response.headers.get("Pagination")
            )
          }
          return paginatedResult
        })
      )
  }

  getByMfaId(id: number): Observable<MasterFranchiseDefault[]> {
    return this.http.get<MasterFranchiseDefault[]>(
      this.baseUrl +
        "MasterFranchiseAgreements/" +
        id +
        "/MasterFranchiseDefaults/"
    )
  }
}
