import { Injectable } from "@angular/core"
import { Apollo } from "apollo-angular"
import { HttpLink } from "apollo-angular-link-http"
import { InMemoryCache } from "apollo-cache-inmemory"
import gql from "graphql-tag"
import { EnviromentService } from "./enviroment.service"
import { SalesResponse } from "app/_models/sales/SalesResponse"
import { ApolloQueryResult } from "apollo-client"
import { Observable } from "rxjs"
import { SalesAmount } from "app/_models/SalesAmount"
import { CenterSalesAmount } from "app/_models/CenterSalesAmount"
import { setContext } from "apollo-link-context"
import { ApolloLink } from "apollo-link"
import { onError } from "apollo-link-error"
import { start } from "repl"
import { AngularFireAuth } from "@angular/fire/auth"

@Injectable({
  providedIn: "root",
})
export class SalesService {
  constructor(
    private apollo: Apollo,
    httpLink: HttpLink,
    private afAuth: AngularFireAuth,
    private envService: EnviromentService
  ) {
    const auth = setContext(async (operation, context) => {
      const user: any = await this.getCurrentUser()
      const token = await user.getIdToken(true)
      return {
        headers: {
          Authorization: token ? `Bearer ${token}` : "",
        },
      }
    })

    const uri = envService.getConfig().apiSales

    apollo.create(
      {
        link: ApolloLink.from([auth, httpLink.create({ uri })]),
        cache: new InMemoryCache(),
      },
      "sales"
    )
  }

  async getCurrentUser() {
    return new Promise(async (resolve, reject) => {
      const unsubscribe = await this.afAuth.onAuthStateChanged((user) => {
        resolve(user)
      }, reject)
    })
  }

  GetYearOverYearSales(
    centerNumber,
    startYear,
    endYear
  ): Promise<ApolloQueryResult<SalesResponse>> {
    return this.apollo
      .use("sales")
      .query<SalesResponse>({
        query: gql`
          query center($centerNumber: ID!, $startYear: Int, $endYear: Int) {
            center(centerNumber: $centerNumber) {
              centerNumber
              webNumber
              monthlySales(
                input: { startYear: $startYear, endYear: $endYear }
              ) {
                sale {
                  date
                  businessDays
                  amount
                }
                comparableSale {
                  date
                  businessDays
                  amount
                }
                percentChange
                aggregation
              }
            }
          }
        `,
        variables: {
          centerNumber: centerNumber,
          startYear: startYear,
          endYear: endYear,
        },
      })
      .toPromise()
  }

  getSalesByCountryByYear(
    countryCode,
    StartYear,
    EndYear
  ): Promise<ApolloQueryResult<SalesResponse>> {
    return this.apollo
      .use("sales")
      .query<SalesResponse>({
        query: gql`
          query country($countryCode: String!, $startYear: Int, $endYear: Int) {
            country(countryCode: $countryCode) {
              countryCode
              countryName
              monthlySales(
                input: { startYear: $startYear, endYear: $endYear }
              ) {
                sale {
                  date
                  amount
                }
              }
            }
          }
        `,
        variables: {
          countryCode: countryCode,
          startYear: StartYear,
          endYear: EndYear,
        },
      })
      .toPromise()
  }

  getAllCenterMonthlySales(
    countryCode,
    StartYear,
    EndYear
  ): Promise<ApolloQueryResult<SalesResponse>> {
    return this.apollo
      .use("sales")
      .query<SalesResponse>({
        query: gql`
          query centersByCountry(
            $countryCode: String!
            $startYear: Int
            $endYear: Int
          ) {
            centersByCountry(
              input: {
                countryCode: $countryCode
                startYear: $startYear
                endYear: $endYear
              }
            ) {
              centerNumber
              webNumber
              vanityName
              countryCode
              countryName
              regionName
              stateProvinceCode
              stateProvinceName
              city
              monthlySales {
                sale {
                  date
                  amount
                  businessDays
                }
                comparableSale {
                  date
                  amount
                  businessDays
                }
                percentChange
                aggregation
              }
            }
          }
        `,
        variables: {
          countryCode: countryCode,
          startYear: StartYear,
          endYear: EndYear,
        },
      })
      .toPromise()
  }
}
