let env = "development";

if (
  window.location.origin === "http://localhost:4200" ||
  window.location.origin === "https://staging.diamond.fastsigns.com" ||
  window.location.origin === "https://diamond.staging.fastsigns.com"
) {
  env = "development";
}
if (
  window.location.origin === "https://staging.diamond.fastsigns.com" ||
  window.location.origin === "https://diamond.staging.fastsigns.com"
) {
  env = "staging";
}
if (
  window.location.origin === "https://fs-diamond-codeauthority.web.app" ||
  window.location.origin === "https://codeauthority.diamond.fastsigns.com"
) {
  env = "codeauthority";
}
if (window.location.origin === "https://diamond.fastsigns.com") {
  env = "production";
}

const config = {
  development: {
    apiV3Url: "http://localhost:5001/fastsigns-dev/us-central1/fs_centers_api/",
    apiV2Url: "https://api.staging.fastsigns.com/FSCentersAPI/api/v2/",
    apiMfa: "https://api.staging.fastsigns.com/FSCentersAPI/api/v1/",
    apiEmail: "https://api.staging.fastsigns.com/FSEmailAPI/api/v1/",
    apiSales:
      "https://us-central1-fastsigns-dev.cloudfunctions.net/sales_reporting-graphql",
    apiSalesv2: "https://api.staging.fastsigns.com/FSSalesReportingAPI/",
    apiUrlBase: "https://api.staging.fastsigns.com",
    allowedUrls: ["https://api.staging.fastsigns.com"],
    googleClientId:
      "550553899833-5jcul1c71197qtt3tpulc6hr6o628mb7.apps.googleusercontent.com",
    authUri: "https://us-central1-fastsigns-dev.cloudfunctions.net/auth_v2-api",
    templateSoonToExpireId: 1,
    templateExpireId: 18,
  },
  staging: {
    apiV3Url:
      "https://us-central1-avid-shape-205119.cloudfunctions.net/fs_centers_api/",
    apiV2Url: "https://api.staging.fastsigns.com/FSCentersAPI/api/v2/",
    apiMfa: "https://api.staging.fastsigns.com/FSCentersAPI/api/v1/",
    apiEmail: "https://api.staging.fastsigns.com/FSEmailAPI/api/v1/",
    apiSales:
      "https://us-central1-fastsigns-dev.cloudfunctions.net/sales_reporting-graphql",
    apiSalesv2: "https://api.staging.fastsigns.com/FSSalesReportingAPI/",
    apiUrlBase: "https://api.staging.fastsigns.com",
    allowedUrls: ["https://api.staging.fastsigns.com"],
    googleClientId:
      "550553899833-5jcul1c71197qtt3tpulc6hr6o628mb7.apps.googleusercontent.com",
    authUri: "https://us-central1-fastsigns-dev.cloudfunctions.net/auth_v2-api",
    templateSoonToExpireId: 1,
    templateExpireId: 18,
  },
  codeauthority: {
    apiV3Url: "",
    apiV2Url: "https://diamondv2.staging.fastsigns.com/FSCentersAPI/api/v2/",
    apiMfa: "https://diamondv2.staging.fastsigns.com/FSCentersAPI/api/v1/",
    apiEmail: "https://api.staging.fastsigns.com/FSEmailAPI/api/v1/",
    apiSales:
      "https://us-central1-fastsigns-dev.cloudfunctions.net/sales_reporting-graphql",
    apiSalesv2: "https://api.staging.fastsigns.com/FSSalesReportingAPI/",
    apiUrlBase: "https://api.staging.fastsigns.com",
    allowedUrls: [
      "https://api.staging.fastsigns.com",
      "https://diamondv2.staging.fastsigns.com",
    ],
    googleClientId:
      "550553899833-5jcul1c71197qtt3tpulc6hr6o628mb7.apps.googleusercontent.com",
    authUri: "https://us-central1-fastsigns-dev.cloudfunctions.net/auth_v2-api",
    templateSoonToExpireId: 1,
    templateExpireId: 18,
  },
  production: {
    apiV3Url: "https://us-central1-fs-dal01.cloudfunctions.net/fs_centers_api/",
    apiV2Url: "https://api.fastsigns.com/FSCentersAPI/api/v2/",
    apiMfa: "https://api.fastsigns.com/FSCentersAPI/api/v1/",
    apiEmail: "https://api.fastsigns.com/FSEmailAPI/api/v1/",
    apiSales:
      "https://us-central1-fastsigns.cloudfunctions.net/sales_reporting-graphql",
    apiSalesv2: "https://api.fastsigns.com/FSSalesReportingAPI/",
    apiUrlBase: "https://api.fastsigns.com",
    apiReportingUrl: "https://fs-reportingapi-7puomjdo6q-uc.a.run.app",
    allowedUrls: ["https://api.fastsigns.com"],
    googleClientId:
      "218004037907-kuvcrker64inc23mt269ga5ms5jh9ku6.apps.googleusercontent.com",
    authUri: "https://us-central1-fastsigns.cloudfunctions.net/auth_v2-api",
    templateSoonToExpireId: 33,
    templateExpireId: 32,
  },
};
const environment = config[env];

export { environment };
