import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http"
import { Injectable } from "@angular/core"
import { AngularFireAuth } from "@angular/fire/auth"
import { Observable } from "rxjs/Observable"
import { take, mergeMap, switchMap } from "rxjs/operators"
import { AuthService } from "../auth.service"

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {
  constructor(private auth: AuthService, private afAuth: AngularFireAuth) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (
      (request.url.includes("api.fastsigns") ||
        request.url.includes("cloudfunctions") ||
        request.url.includes("api.staging")) &&
      !request.url.includes("graphql")
    ) {
      return this.auth.user$.pipe(
        take(1),
        switchMap((user) => {
          if (user) {
            return this.afAuth.idToken
              .pipe(take(1))
              .toPromise()
              .then((token) => {
                return request.clone({
                  setHeaders: { Authorization: `Bearer ${token}` },
                })
              })
          }
        }),
        mergeMap((request) => next.handle(request))
      )
    } else {
      return next.handle(request)
    }
  }
}
