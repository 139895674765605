import { EnviromentService } from "./_services/enviroment.service"
import { Component } from "@angular/core"
import { environment } from "./../environments/environment"
import { AuthService } from "./_services/auth.service"

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent {
  baseUrl = ""
  constructor(
    public auth: AuthService,
    private enviromentService: EnviromentService
  ) {
    this.baseUrl = location.origin
  }
}
