import { Injectable } from "@angular/core";
import { EnviromentService } from "./enviroment.service";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { LegalEntity } from "app/_models/LegalEntity";

@Injectable()
export class LegalEntityService {
  baseUrl = "";
  constructor(
    private http: HttpClient,
    private enviromentService: EnviromentService
  ) {
    this.baseUrl = enviromentService.getConfig().apiV3Url;
  }

  add(add: LegalEntity): Observable<number> {
    return this.http.post<number>(this.baseUrl + "legalEntities", add);
  }

  delete(id: number): Observable<boolean> {
    return this.http.delete<boolean>(this.baseUrl + "legalEntities/" + id);
  }

  update(update: LegalEntity): Observable<number> {
    return this.http.put<number>(
      this.baseUrl + "legalEntities/" + update.id,
      update
    );
  }

  get(id: number): Observable<LegalEntity> {
    return this.http.get<LegalEntity>(this.baseUrl + "/legalEntities/" + id);
  }

  getByCenterId(centerId: number): Observable<LegalEntity[]> {
    return this.http.get<LegalEntity[]>(
      this.baseUrl + "centers/" + centerId + "/legalEntities"
    );
  }
}
