
<div *ngIf="auth.user$ | async as user">
  <p *ngIf="user?.impersonating" style="text-align: center; background-color: blue;margin: 0;"><span style="color:white;">
    <strong>You are impersonating user: {{user.email}}</strong></span>
  </p>
</div>

<p *ngIf="baseUrl.includes('staging')" style="text-align: center; background-color: red;margin: 0;"><span style="color:white;">
  <strong>This is the staging environment for Diamond</strong></span>
</p>
<p *ngIf="baseUrl.includes('localhost')" style="text-align: center; background-color: gray;margin: 0;"><span style="color:white;">
  <strong>This is the development environment for Diamond</strong></span>
</p>
<router-outlet></router-outlet>
  <!-- Modal -->
  <div class="modal fade" id="logoutModal" tabindex="-1" role="dialog" aria-labelledby="logoutModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="logoutModalLabel">Session Expired</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <p>You have been logged out, please login again.</p>
        </div>
        <div class="modal-footer">
          <a href="/dashboard" class="btn btn-primary" style="color:white;" >Login</a>
        </div>
      </div>
    </div>
  </div>