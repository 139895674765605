import { LetterOfCredit } from "./../../_models/mfa/LetterOfCredit"
import { AddLetterOfCredit } from "./../../_models/mfa/AddLetterOfCredit"

import { EnviromentService } from "./../enviroment.service"
import { Injectable } from "@angular/core"
import { HttpClient, HttpParams } from "@angular/common/http"
import { Observable } from "rxjs"
import { PaginatedResult } from "app/_models/Pagination"
import { map } from "rxjs/operators"

@Injectable()
export class LetterOfCreditService {
  baseUrl = ""

  constructor(
    private http: HttpClient,
    private enviromentService: EnviromentService
  ) {
    this.baseUrl = enviromentService.getConfig().apiMfa
  }

  add(add: AddLetterOfCredit): Observable<number> {
    return this.http.post<number>(this.baseUrl + "LetterOfCredits", add)
  }

  delete(id: number): Observable<boolean> {
    return this.http.delete<boolean>(this.baseUrl + "LetterOfCredits/" + id)
  }

  update(update: LetterOfCredit): Observable<number> {
    return this.http.put<number>(
      this.baseUrl + "LetterOfCredits/" + update.id,
      update
    )
  }

  get(id: number): Observable<LetterOfCredit> {
    return this.http.get<LetterOfCredit>(this.baseUrl + "LetterOfCredits/" + id)
  }

  getLetterOfCredits(
    page?,
    itemsPerPage?,
    LetterOfCreditParams?
  ): Observable<PaginatedResult<LetterOfCredit[]>> {
    const paginatedResult: PaginatedResult<LetterOfCredit[]> =
      new PaginatedResult<LetterOfCredit[]>()

    let params = new HttpParams()

    if (page != null && itemsPerPage != null) {
      params = params.append("pageNumber", page)
      params = params.append("pageSize", itemsPerPage)
    }

    return this.http
      .get<LetterOfCredit[]>(this.baseUrl + "LetterOfCredits", {
        observe: "response",
        params,
      })
      .pipe(
        map((response) => {
          paginatedResult.result = response.body
          if (response.headers.get("Pagination") != null) {
            paginatedResult.pagination = JSON.parse(
              response.headers.get("Pagination")
            )
          }
          return paginatedResult
        })
      )
  }

  getByMfaId(id: number): Observable<LetterOfCredit[]> {
    return this.http.get<LetterOfCredit[]>(
      this.baseUrl + "MasterFranchiseAgreements/" + id + "/LetterOfCredits/"
    )
  }
}
