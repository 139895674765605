import { EnviromentService } from './enviroment.service'
import { AngularFireAuth } from '@angular/fire/auth'
import { UserResponse } from 'app/_models/user/UserResponse'
import { Observable } from 'rxjs'
import { Injectable } from '@angular/core'
import firebase from 'firebase/app'
import axios from 'axios'

@Injectable({
  providedIn: 'root',
})
export class AuthApiService {
  constructor(private afAuth: AngularFireAuth, private envService: EnviromentService) {
    this.uri = envService.getConfig().authUri
  }

  uri: string

  // only to get current user once
  // later, firebase may have a firebase.auth().getCurrentUser() that may replace this method
  getCurrentUser(): Promise<firebase.User> {
    return new Promise((resolve, reject) => {
      const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
        unsubscribe()
        resolve(user)
      }, reject)
    })
  }

  async getUserInfo(userId) {
    //NEW STUFF
    let user = await this.getCurrentUser()
    let token = await user.getIdToken(true)
    const apiURL = this.uri + `/v1/user/${userId}`

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    }

    try {
      const response = await axios.get(apiURL, config)
      return response.data
    } catch (err) {
      console.log('Error retrieving user information.')
      return null
    }
  }
}
