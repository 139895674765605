import { Injectable } from '@angular/core'
import { AngularFireAuth } from '@angular/fire/auth'
import firebase from 'firebase/app'
import 'firebase/auth'
import { Observable, of } from 'rxjs'
import { switchMap } from 'rxjs/operators'
import { AuthApiService } from './authapi.service'

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  user$: Observable<any>

  constructor(private afAuth: AngularFireAuth, private authApi: AuthApiService) {
    this.user$ = this.afAuth.authState.pipe(
      switchMap((user) => {
        if (user) {
          return this.authApi.getUserInfo(user.uid).then(async (userData) => {
            const tokenResult = await user.getIdTokenResult()
            const groups = userData.inGroups.map((g) => g.name.toLowerCase())
            const data = { ...user, groups, impersonating: tokenResult.claims.impersonator ? true : false }
            return data
          })
        } else {
          return of(null)
        }
      })
    )
  }

  signInGoogleAuth() {
    const googleAuthProvider = new firebase.auth.GoogleAuthProvider()
    return this.afAuth.signInWithPopup(googleAuthProvider)
  }

  signInWithToken(token: string) {
    return this.afAuth.signInWithCustomToken(token)
  }

  signOut() {
    return this.afAuth.signOut()
  }
}
