import { AddShareHolder } from "./../_models/AddShareHolder";
import { Injectable } from "@angular/core";
import { EnviromentService } from "./enviroment.service";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { Contact } from "../_models/Contact";
import { ShareHolder } from "../_models/IShareHolder";

@Injectable()
export class ShareholderService {
  baseUrl = "";

  constructor(
    private http: HttpClient,
    private enviromentService: EnviromentService
  ) {
    this.baseUrl = enviromentService.getConfig().apiV3Url;
  }

  add(add: ShareHolder): Observable<number> {
    return this.http.post<number>(this.baseUrl + "shareholders", add);
  }

  delete(id: number): Observable<boolean> {
    return this.http.delete<boolean>(this.baseUrl + "shareholders/" + id);
  }

  get(id: number): Observable<Contact> {
    return this.http.get<Contact>(this.baseUrl + "/shareholders/" + id);
  }

  getByCenterIdFinancialId(
    centerId: number,
    financialid: number
  ): Observable<AddShareHolder[]> {
    return this.http.get<AddShareHolder[]>(
      this.baseUrl +
        "centers/" +
        centerId +
        "/financials/" +
        financialid +
        "/shareholders"
    );
  }
}
