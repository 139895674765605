import { Development } from "./../../_models/mfa/Development"
import { AddDevelopment } from "./../../_models/mfa/AddDevelopment"

import { EnviromentService } from "./../enviroment.service"
import { Injectable } from "@angular/core"
import { HttpClient, HttpParams } from "@angular/common/http"
import { Observable } from "rxjs"
import { PaginatedResult } from "app/_models/Pagination"
import { map } from "rxjs/operators"

@Injectable()
export class DevelopmentService {
  baseUrl = ""

  constructor(
    private http: HttpClient,
    private enviromentService: EnviromentService
  ) {
    this.baseUrl = enviromentService.getConfig().apiMfa
  }

  add(add: AddDevelopment): Observable<number> {
    return this.http.post<number>(this.baseUrl + "Developments", add)
  }

  delete(id: number): Observable<boolean> {
    return this.http.delete<boolean>(this.baseUrl + "Developments/" + id)
  }

  update(update: Development): Observable<number> {
    return this.http.put<number>(
      this.baseUrl + "Developments/" + update.id,
      update
    )
  }

  get(id: number): Observable<Development> {
    return this.http.get<Development>(this.baseUrl + "Developments/" + id)
  }

  getDevelopments(
    page?,
    itemsPerPage?,
    DevelopmentParams?
  ): Observable<PaginatedResult<Development[]>> {
    const paginatedResult: PaginatedResult<Development[]> = new PaginatedResult<
      Development[]
    >()

    let params = new HttpParams()

    if (page != null && itemsPerPage != null) {
      params = params.append("pageNumber", page)
      params = params.append("pageSize", itemsPerPage)
    }

    return this.http
      .get<Development[]>(this.baseUrl + "Developments", {
        observe: "response",
        params,
      })
      .pipe(
        map((response) => {
          paginatedResult.result = response.body
          if (response.headers.get("Pagination") != null) {
            paginatedResult.pagination = JSON.parse(
              response.headers.get("Pagination")
            )
          }
          return paginatedResult
        })
      )
  }

  getByMfaId(id: number): Observable<Development[]> {
    return this.http.get<Development[]>(
      this.baseUrl + "MasterFranchiseAgreements/" + id + "/Developments/"
    )
  }
}
