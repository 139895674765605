import { AlertifyService } from './../_services/alertify.service';
import { Observable ,  of } from 'rxjs';
import { Router, Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { catchError } from '../../../node_modules/rxjs/operators';
import { IAuditLog } from 'app/_models/IAduitLog';
import { AuditLogService } from 'app/_services/auditlog.service';

@Injectable()
export class AuditResolver implements Resolve<IAuditLog[]> {
    pageNumber = 1;
    pageSize = 25;

    constructor(private auditService: AuditLogService, private router: Router, private alertify: AlertifyService) {}

    resolve(route: ActivatedRouteSnapshot): Observable<IAuditLog[]> {
        return this.auditService.getAudits(this.pageNumber, this.pageSize).pipe(
            catchError(error => {
                this.alertify.error(error.status + ': ' + error.statusText);
                this.router.navigate(['/dashboard']);
                return of(null);
            })
        );
    }
}