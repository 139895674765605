import { GuarantorArrangement } from "./../../_models/mfa/GuarantorArrangement"
import { AddGuarantorArrangement } from "./../../_models/mfa/AddGuarantorArrangement"

import { EnviromentService } from "./../enviroment.service"
import { Injectable } from "@angular/core"
import { HttpClient, HttpParams } from "@angular/common/http"
import { Observable } from "rxjs"
import { PaginatedResult } from "app/_models/Pagination"
import { map } from "rxjs/operators"

@Injectable()
export class GuarantorArrangementService {
  baseUrl = ""

  constructor(
    private http: HttpClient,
    private enviromentService: EnviromentService
  ) {
    this.baseUrl = enviromentService.getConfig().apiMfa
  }

  add(add: AddGuarantorArrangement): Observable<number> {
    return this.http.post<number>(this.baseUrl + "GuarantorArrangements", add)
  }

  delete(id: number): Observable<boolean> {
    return this.http.delete<boolean>(
      this.baseUrl + "GuarantorArrangements/" + id
    )
  }

  update(update: GuarantorArrangement): Observable<number> {
    return this.http.put<number>(
      this.baseUrl + "GuarantorArrangements/" + update.id,
      update
    )
  }

  get(id: number): Observable<GuarantorArrangement> {
    return this.http.get<GuarantorArrangement>(
      this.baseUrl + "GuarantorArrangements/" + id
    )
  }

  addContact(id: number, contactId: number): Observable<boolean> {
    return this.http.post<boolean>(
      this.baseUrl + "guarantorArrangements/" + id + "/contacts/" + contactId,
      null
    )
  }

  removeContact(id: number, contactId: number): Observable<boolean> {
    return this.http.delete<boolean>(
      this.baseUrl + "guarantorArrangements/" + id + "/contacts/" + contactId
    )
  }

  getContacts(id: number): Observable<number[]> {
    return this.http.get<number[]>(
      this.baseUrl + "guarantorArrangements/" + id + "/contacts/"
    )
  }

  getGuarantorArrangements(
    page?,
    itemsPerPage?,
    GuarantorArrangementParams?
  ): Observable<PaginatedResult<GuarantorArrangement[]>> {
    const paginatedResult: PaginatedResult<GuarantorArrangement[]> =
      new PaginatedResult<GuarantorArrangement[]>()

    let params = new HttpParams()

    if (page != null && itemsPerPage != null) {
      params = params.append("pageNumber", page)
      params = params.append("pageSize", itemsPerPage)
    }

    return this.http
      .get<GuarantorArrangement[]>(this.baseUrl + "GuarantorArrangements", {
        observe: "response",
        params,
      })
      .pipe(
        map((response) => {
          paginatedResult.result = response.body
          if (response.headers.get("Pagination") != null) {
            paginatedResult.pagination = JSON.parse(
              response.headers.get("Pagination")
            )
          }
          return paginatedResult
        })
      )
  }

  getByMfaId(id: number): Observable<GuarantorArrangement[]> {
    return this.http.get<GuarantorArrangement[]>(
      this.baseUrl +
        "MasterFranchiseAgreements/" +
        id +
        "/GuarantorArrangements/"
    )
  }
}
