import { AddMarketingRegion } from "./../_models/AddMarketingRegion";
import { AddMarketing } from "./../_models/AddMarketing";
import { MarketingRegions } from "app/_models/MarketingRegions";
import { Injectable } from "@angular/core";
import { EnviromentService } from "./enviroment.service";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { Marketing } from "app/_models/Marketing";

@Injectable()
export class MarketingService {
  baseUrl = "";

  constructor(
    private http: HttpClient,
    private enviromentService: EnviromentService
  ) {
    this.baseUrl = enviromentService.getConfig().apiV3Url;
  }

  add(add: AddMarketing): Observable<number> {
    return this.http.post<number>(this.baseUrl + "marketings", add);
  }

  delete(id: number): Observable<boolean> {
    return this.http.delete<boolean>(this.baseUrl + "marketings/" + id);
  }

  update(update: Marketing): Observable<number> {
    return this.http.put<number>(
      this.baseUrl + "marketings/" + update.id,
      update
    );
  }

  get(id: number): Observable<Marketing> {
    return this.http.get<Marketing>(this.baseUrl + "/marketings/" + id);
  }

  getRegions(centerId: number, id: number): Observable<MarketingRegions[]> {
    return this.http.get<MarketingRegions[]>(
      this.baseUrl + "centers/" + centerId + "/marketings/" + id + "/regions"
    );
  }

  addRegion(add: AddMarketingRegion): Observable<number> {
    return this.http.post<number>(
      this.baseUrl + "marketings/" + add.marketingId + "/regions",
      add
    );
  }

  deleteRegion(marketingId: number, regionId: number): Observable<boolean> {
    return this.http.delete<boolean>(
      this.baseUrl + "marketings/" + marketingId + "/regions/" + regionId
    );
  }

  getByCenterId(centerId: number): Observable<Marketing[]> {
    return this.http.get<Marketing[]>(
      this.baseUrl + "centers/" + centerId + "/marketings"
    );
  }
}
