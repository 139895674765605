import { environment } from "../environments/environment"
import { PrintService } from "./_services/print.service"
import { AgreementsExpReportResolver } from "./_resolvers/agreements-exp-report-resolver"

import { MasterFranchiseDocumentService } from "./_services/mfa/master-franchise-document.service"
import { MasterFranchiseTrainingService } from "./_services/mfa/master-franchise-training.service"
import { BusinessReviewSupportService } from "app/_services/mfa/business-review-support.service"
import { RenewalTermService } from "./_services/mfa/renewal-term.service"
import { EnviromentService } from "./_services/enviroment.service"
import { EmailaddressService } from "./_services/emailaddress.service"
import { DetailService } from "app/_services/detail.service"
import { TaxInfoService } from "./_services/taxinfo.service"
import { LegalEntityService } from "./_services/legalentity.service"
import { ContactsListResolver } from "./_resolvers/contacts-list-resolver"
import { ShareholderService } from "./_services/shareholder.service"
import { GuarantorService } from "./_services/guarantor.service"
import { ComplianceReportResolver } from "./_resolvers/compliance-report.resolver"
import { SalesService } from "./_services/sales.service"
import { CentersEditResolver } from "./_resolvers/centers-edit.resolver"
import { AlertifyService } from "./_services/alertify.service"
import { CenterService } from "./_services/center.service"
import { NoteGroupsService } from "./_services/noteGroups.service"

import { CentersListResolver } from "./_resolvers/centers-list.resolver"
import { AuthGuard } from "./shared/auth/auth.guard"

import { BrowserAnimationsModule } from "@angular/platform-browser/animations"
import { NgModule, APP_INITIALIZER } from "@angular/core"
import { FormsModule } from "@angular/forms"
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http"
import { RouterModule } from "@angular/router"
import { NgbModule, NgbPaginationModule } from "@ng-bootstrap/ng-bootstrap"
import { AppRoutingModule } from "./app.routing"
import { ComponentsModule } from "./components/components.module"
import { NgProgressModule } from "@ngx-progressbar/core"
import { NgProgressHttpModule } from "@ngx-progressbar/http"
import { BrowserModule } from "@angular/platform-browser"
import { DxDataGridModule } from "devextreme-angular"

import { AppComponent } from "./app.component"

import { AdminLayoutComponent } from "./layouts/admin-layout/admin-layout.component"
import { InsuranceResolver } from "./_resolvers/insurance.resolver"
import { ReportService } from "./_services/report.service"
import { AwardService } from "./_services/award.service"
import { ContactService } from "./_services/contact.service"
import { FinancialService } from "./_services/financial.service"
import { InsuranceService } from "./_services/insurance.service"
import { AgreementsService } from "./_services/agreements.service"
import { FranchiseDevelopmentService } from "./_services/franchise-development.service"
import { MarketingService } from "./_services/marketing.service"
import { CertificationService } from "./_services/certification.service"
import { TrainingService } from "./_services/training.service"
import { EquipmentService } from "./_services/equipment.service"
import { ContactsEditResolver } from "./_resolvers/contacts-edit-resolver"
import { GroupsService } from "./_services/groups.service"
import { AuditLogService } from "./_services/auditlog.service"
import { AuditResolver } from "./_resolvers/auditlog.resolver"
import { PastOwnerService } from "./_services/pastownerservice.service"
import { CountryStateProvinceService } from "./_services/country-state-province.service"
import { AutocompleteService } from "./_services/autocomplete.service"
import { MessageService } from "./_services/message.service"
import { MasterFranchiseAgreementService } from "./_services/mfa/master-franchise-agreement.service"
import { MasterFranchiseAgreementResolver } from "./_resolvers/master-franchise-agreement.resolver"
import { MasterFranchiseAgreementEditResolver } from "./_resolvers/master-franchise-agreement-edit.resolver"
import { LetterOfIntentService } from "./_services/mfa/letter-of-intent.service"
import { MasterFranchiseDefaultService } from "./_services/mfa/master-franchise-default.service"
import { LetterOfCreditService } from "./_services/mfa/letter-of-credit.service"
import { FranchiseDisclosureService } from "./_services/mfa/franchise-disclosure.service"
import { SubFranchiseOptionService } from "./_services/mfa/sub-franchise-option.service"
import { GuarantorArrangementService } from "./_services/mfa/guarantor-arrangement-service"
import { SubFranchiseeFeeService } from "./_services/mfa/sub-franchisee-fee.service"
import { RecordReportingService } from "./_services/mfa/record-reporting.service"
import { MasterFranchiseeRoyaltyService } from "./_services/mfa/master-franchisee-royalty.service"
import { DevelopmentService } from "./_services/mfa/development.service"
import { DevelopmentScheduleService } from "./_services/mfa/development-schedule.service"
import { MasterFranchiseOwnerService } from "./_services/mfa/master-franchise-owner.service"
import { QuillModule } from "ngx-quill"
import { DatePipe } from "@angular/common"
import { ApolloModule } from "apollo-angular"
import { HttpLinkModule } from "apollo-angular-link-http"
import { AngularFireModule } from "@angular/fire"
import { AngularFireAuthModule } from "@angular/fire/auth"
import { AuthService } from "./_services/auth.service"
import { AuthApiService } from "./_services/authapi.service"
import { AuthInterceptorService } from "./_services/interceptor/authInterceptor.service"

const appInitializerFn = (appConfig: EnviromentService) => {
  return () => {
    return appConfig.loadAppConfig()
  }
}

const config = {
  apiKey: environment.apiKey,
  authDomain: environment.authDomain,
  databaseURL: environment.databaseURL,
  projectId: environment.projectId,
  storageBucket: environment.storageBucket,
  messagingSenderId: environment.messagingSenderId,
  appId: environment.appId,
  measurementId: environment.measurementId,
}

@NgModule({
  imports: [
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    ComponentsModule,
    RouterModule,
    AppRoutingModule,
    NgbPaginationModule,
    NgbModule,
    NgProgressModule.forRoot(),
    NgProgressHttpModule,
    BrowserModule,
    DxDataGridModule,
    QuillModule.forRoot(),
    ApolloModule,
    HttpLinkModule,
    AngularFireModule.initializeApp(config),
    AngularFireAuthModule,
  ],
  declarations: [AppComponent, AdminLayoutComponent],
  providers: [
    AgreementsService,
    AwardService,
    CenterService,
    ContactService,
    FinancialService,
    FranchiseDevelopmentService,
    InsuranceService,
    MarketingService,
    AuditLogService,
    CertificationService,
    ShareholderService,
    GuarantorService,
    TrainingService,
    EmailaddressService,
    LegalEntityService,
    PastOwnerService,
    EquipmentService,
    GroupsService,
    DetailService,
    SalesService,
    AlertifyService,
    TaxInfoService,
    ReportService,
    CountryStateProvinceService,
    AutocompleteService,
    MessageService,
    NoteGroupsService,
    CentersListResolver,
    ContactsListResolver,
    CentersEditResolver,
    ContactsEditResolver,
    MasterFranchiseAgreementResolver,
    MasterFranchiseAgreementEditResolver,
    AuditResolver,
    InsuranceResolver,
    ComplianceReportResolver,
    AgreementsExpReportResolver,
    AuthGuard,
    EnviromentService,
    MasterFranchiseAgreementService,
    LetterOfIntentService,
    LetterOfCreditService,
    MasterFranchiseDefaultService,
    BusinessReviewSupportService,
    RenewalTermService,
    MasterFranchiseTrainingService,
    MasterFranchiseDocumentService,
    FranchiseDisclosureService,
    SubFranchiseOptionService,
    GuarantorArrangementService,
    SubFranchiseeFeeService,
    RecordReportingService,
    MasterFranchiseeRoyaltyService,
    DevelopmentService,
    DevelopmentScheduleService,
    MasterFranchiseOwnerService,
    PrintService,
    DatePipe,
    AuthService,
    AuthApiService,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFn,
      multi: true,
      deps: [EnviromentService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
