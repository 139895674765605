import { MasterFranchiseTraining } from "./../../_models/mfa/MasterFranchiseTraining"
import { AddMasterFranchiseTraining } from "./../../_models/mfa/AddMasterFranchiseTraining"

import { EnviromentService } from "./../enviroment.service"
import { Injectable } from "@angular/core"
import { HttpClient, HttpParams } from "@angular/common/http"
import { Observable } from "rxjs"
import { PaginatedResult } from "app/_models/Pagination"
import { map } from "rxjs/operators"

@Injectable()
export class MasterFranchiseTrainingService {
  baseUrl = ""

  constructor(
    private http: HttpClient,
    private enviromentService: EnviromentService
  ) {
    this.baseUrl = enviromentService.getConfig().apiMfa
  }

  add(add: AddMasterFranchiseTraining): Observable<number> {
    return this.http.post<number>(
      this.baseUrl + "MasterFranchiseTrainings",
      add
    )
  }

  delete(id: number): Observable<boolean> {
    return this.http.delete<boolean>(
      this.baseUrl + "MasterFranchiseTrainings/" + id
    )
  }

  update(update: MasterFranchiseTraining): Observable<number> {
    return this.http.put<number>(
      this.baseUrl + "MasterFranchiseTrainings/" + update.id,
      update
    )
  }

  get(id: number): Observable<MasterFranchiseTraining> {
    return this.http.get<MasterFranchiseTraining>(
      this.baseUrl + "MasterFranchiseTrainings/" + id
    )
  }

  getMasterFranchiseTrainings(
    page?,
    itemsPerPage?,
    MasterFranchiseTrainingParams?
  ): Observable<PaginatedResult<MasterFranchiseTraining[]>> {
    const paginatedResult: PaginatedResult<MasterFranchiseTraining[]> =
      new PaginatedResult<MasterFranchiseTraining[]>()

    let params = new HttpParams()

    if (page != null && itemsPerPage != null) {
      params = params.append("pageNumber", page)
      params = params.append("pageSize", itemsPerPage)
    }

    return this.http
      .get<MasterFranchiseTraining[]>(
        this.baseUrl + "MasterFranchiseTrainings",
        { observe: "response", params }
      )
      .pipe(
        map((response) => {
          paginatedResult.result = response.body
          if (response.headers.get("Pagination") != null) {
            paginatedResult.pagination = JSON.parse(
              response.headers.get("Pagination")
            )
          }
          return paginatedResult
        })
      )
  }

  getByMfaId(id: number): Observable<MasterFranchiseTraining[]> {
    return this.http.get<MasterFranchiseTraining[]>(
      this.baseUrl +
        "MasterFranchiseAgreements/" +
        id +
        "/MasterFranchiseTrainings/"
    )
  }
}
