import { SubFranchiseeFee } from "./../../_models/mfa/SubFranchiseeFee"
import { AddSubFranchiseeFee } from "./../../_models/mfa/AddSubFranchiseeFee"

import { EnviromentService } from "./../enviroment.service"
import { Injectable } from "@angular/core"
import { HttpClient, HttpParams } from "@angular/common/http"
import { Observable } from "rxjs"
import { PaginatedResult } from "app/_models/Pagination"
import { map } from "rxjs/operators"

@Injectable()
export class SubFranchiseeFeeService {
  baseUrl = ""

  constructor(
    private http: HttpClient,
    private enviromentService: EnviromentService
  ) {
    this.baseUrl = enviromentService.getConfig().apiMfa
  }

  add(add: AddSubFranchiseeFee): Observable<number> {
    return this.http.post<number>(this.baseUrl + "SubFranchiseeFees", add)
  }

  delete(id: number): Observable<boolean> {
    return this.http.delete<boolean>(this.baseUrl + "SubFranchiseeFees/" + id)
  }

  update(update: SubFranchiseeFee): Observable<number> {
    return this.http.put<number>(
      this.baseUrl + "SubFranchiseeFees/" + update.id,
      update
    )
  }

  get(id: number): Observable<SubFranchiseeFee> {
    return this.http.get<SubFranchiseeFee>(
      this.baseUrl + "SubFranchiseeFees/" + id
    )
  }

  getSubFranchiseeFees(
    page?,
    itemsPerPage?,
    SubFranchiseeFeeParams?
  ): Observable<PaginatedResult<SubFranchiseeFee[]>> {
    const paginatedResult: PaginatedResult<SubFranchiseeFee[]> =
      new PaginatedResult<SubFranchiseeFee[]>()

    let params = new HttpParams()

    if (page != null && itemsPerPage != null) {
      params = params.append("pageNumber", page)
      params = params.append("pageSize", itemsPerPage)
    }

    return this.http
      .get<SubFranchiseeFee[]>(this.baseUrl + "SubFranchiseeFees", {
        observe: "response",
        params,
      })
      .pipe(
        map((response) => {
          paginatedResult.result = response.body
          if (response.headers.get("Pagination") != null) {
            paginatedResult.pagination = JSON.parse(
              response.headers.get("Pagination")
            )
          }
          return paginatedResult
        })
      )
  }

  getByMfaId(id: number): Observable<SubFranchiseeFee[]> {
    return this.http.get<SubFranchiseeFee[]>(
      this.baseUrl + "MasterFranchiseAgreements/" + id + "/SubFranchiseeFees/"
    )
  }
}
