import { Injectable } from "@angular/core"
import { HttpClient, HttpParams } from "@angular/common/http"
import { Observable } from "rxjs"
import { PaginatedResult } from "app/_models/Pagination"
import { map } from "rxjs/operators"
import { EnviromentService } from "../enviroment.service"
import { RenewalTerm } from "../../_models/mfa/RenewalTerm"
import { AddRenewalTerm } from "../../_models/mfa/AddRenewalTerm"

@Injectable()
export class RenewalTermService {
  baseUrl = ""

  constructor(
    private http: HttpClient,
    private enviromentService: EnviromentService
  ) {
    this.baseUrl = enviromentService.getConfig().apiMfa
  }

  add(add: AddRenewalTerm): Observable<number> {
    return this.http.post<number>(this.baseUrl + "RenewalTerms", add)
  }

  delete(id: number): Observable<boolean> {
    return this.http.delete<boolean>(this.baseUrl + "RenewalTerms/" + id)
  }

  update(update: RenewalTerm): Observable<number> {
    return this.http.put<number>(
      this.baseUrl + "RenewalTerms/" + update.id,
      update
    )
  }

  get(id: number): Observable<RenewalTerm> {
    return this.http.get<RenewalTerm>(this.baseUrl + "RenewalTerms/" + id)
  }

  getRenewalTerms(
    page?,
    itemsPerPage?,
    RenewalTermParams?
  ): Observable<PaginatedResult<RenewalTerm[]>> {
    const paginatedResult: PaginatedResult<RenewalTerm[]> = new PaginatedResult<
      RenewalTerm[]
    >()

    let params = new HttpParams()

    if (page != null && itemsPerPage != null) {
      params = params.append("pageNumber", page)
      params = params.append("pageSize", itemsPerPage)
    }

    return this.http
      .get<RenewalTerm[]>(this.baseUrl + "RenewalTerms", {
        observe: "response",
        params,
      })
      .pipe(
        map((response) => {
          paginatedResult.result = response.body
          if (response.headers.get("Pagination") != null) {
            paginatedResult.pagination = JSON.parse(
              response.headers.get("Pagination")
            )
          }
          return paginatedResult
        })
      )
  }

  getByMfaId(id: number): Observable<RenewalTerm[]> {
    return this.http.get<RenewalTerm[]>(
      this.baseUrl + "MasterFranchiseAgreements/" + id + "/RenewalTerms/"
    )
  }
}
