import { Injectable } from "@angular/core"
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from "@angular/router"
import { AuthService } from "app/_services/auth.service"
import { Observable } from "rxjs"
import { take, map } from "rxjs/operators"

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private auth: AuthService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.auth.user$.pipe(
      take(1),
      map((user) => {
        const expectedGroups = route.data.expectedGroups
        if (user) {
          const groups = user.groups
          const result = groups.some((x) => expectedGroups.includes(x))
          if (result) {
            return true
          }
        }
        this.router.navigate(["/home"])
        return false
      })
    )
  }
}
