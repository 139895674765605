import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "assets/data/appConfigs";

@Injectable()
export class EnviromentService {
  private appConfig;

  constructor(private http: HttpClient) {}

  loadAppConfig() {
    this.appConfig = environment;
  }

  public getConfig() {
    return this.appConfig;
  }
}
