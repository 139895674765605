
<div class="logo">
    <a href="#" class="simple-text logo-mini">
      <div class="logo-img" >
           <!-- <img src="./assets/img/diamond.png"/>   -->
           <i class="now-ui-icons objects_diamond" style="font-size: 25px; color: #8c7979; border-radius: 50px; padding: 4px; padding-top: 3.5px; background-color: #FFFFFF; border-style: solid; border-width: thin; -webkit-box-shadow: 0 1px 15px 1px rgba(39, 39, 39, 0.1); box-shadow: 0 1px 15px 1px rgba(39, 39, 39, 0.1);"></i>
      </div>
    </a>
    <a href="#" class="simple-text logo-normal" style=" -webkit-text-stroke: 1.32px; color: #ffffff; font-size: x-large; font-weight: 500; -webkit-font-smoothing: subpixel-antialiased; -webkit-text-stroke-color: #fffffff7; padding: .5rem 0; ">
        Diamond
    </a>
</div>
<div class="sidebar-wrapper">
    <ul class="nav">
        <li routerLinkActive="active" *ngFor="let menuItem of menuItems" class="{{menuItem.class}} nav-item">
            <a [routerLink]="[menuItem.path]" *ngIf="menuItem.display" >
                <i class="now-ui-icons {{menuItem.icon}}"></i>
                <p>{{menuItem.title}}</p>
            </a>
        </li>
    </ul>
    <i class="fa fa-moon" (click)="switchTheme()" style=" position: absolute; bottom: 0; color: white"></i>
</div>