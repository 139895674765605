import { Injectable } from "@angular/core";
import { EnviromentService } from "./enviroment.service";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { AddGroup } from "app/_models/AddGroup";
import { PaginatedResult } from "app/_models/Pagination";
import { Group } from "app/_models/Group";
import { map } from "rxjs/operators";

@Injectable()
export class GroupsService {
  baseUrl = "";

  constructor(
    private http: HttpClient,
    private enviromentService: EnviromentService
  ) {
    this.baseUrl = enviromentService.getConfig().apiV3Url;
  }

  add(add: AddGroup): Observable<number> {
    return this.http.post<number>(this.baseUrl + "groups", add);
  }

  delete(id: number): Observable<boolean> {
    return this.http.delete<boolean>(this.baseUrl + "groups/" + id);
  }

  update(g: Group): Observable<boolean> {
    return this.http.put<boolean>(this.baseUrl + "groups/" + g.id, g);
  }

  addCenter(id: number, centerId: number): Observable<boolean> {
    return this.http.post<boolean>(
      this.baseUrl + "groups/" + id + "/centers/" + centerId,
      null
    );
  }

  removeCenter(id: number, centerId: number): Observable<boolean> {
    return this.http.delete<boolean>(
      this.baseUrl + "groups/" + id + "/centers/" + centerId
    );
  }

  getByCenterId(centerId: number): Observable<Group[]> {
    return this.http.get<Group[]>(
      this.baseUrl + "centers/" + centerId + "/groups/"
    );
  }

  getById(id: number): Observable<Group> {
    return this.http.get<Group>(this.baseUrl + "groups/" + id);
  }

  get(page?, itemsPerPage?): Observable<PaginatedResult<Group[]>> {
    const paginatedResult: PaginatedResult<Group[]> = new PaginatedResult<
      Group[]
    >();
    let params = new HttpParams();

    if (page != null && itemsPerPage != null) {
      params = params.append("pageNumber", page);
      params = params.append("pageSize", itemsPerPage);
    }
    return this.http
      .get<Group[]>(this.baseUrl + "groups/", { observe: "response", params })
      .pipe(
        map((response) => {
          paginatedResult.result = response.body;
          if (response.headers.get("Pagination") != null) {
            paginatedResult.pagination = JSON.parse(
              response.headers.get("Pagination")
            );
          }
          return paginatedResult;
        })
      );
  }
}
