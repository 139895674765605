import { BusinessReviewSupport } from "./../../_models/mfa/BusinessReviewSupport"
import { EnviromentService } from "./../enviroment.service"
import { Injectable } from "@angular/core"
import { HttpClient, HttpParams } from "@angular/common/http"
import { Observable } from "rxjs"
import { PaginatedResult } from "app/_models/Pagination"
import { map } from "rxjs/operators"
@Injectable()
export class BusinessReviewSupportService {
  baseUrl = ""

  constructor(
    private http: HttpClient,
    private enviromentService: EnviromentService
  ) {
    this.baseUrl = enviromentService.getConfig().apiMfa
  }

  add(add: BusinessReviewSupport): Observable<number> {
    return this.http.post<number>(this.baseUrl + "BusinessReviewSupports", add)
  }

  delete(id: number): Observable<boolean> {
    return this.http.delete<boolean>(
      this.baseUrl + "BusinessReviewSupports/" + id
    )
  }

  update(update: BusinessReviewSupport): Observable<number> {
    return this.http.put<number>(
      this.baseUrl + "BusinessReviewSupports/" + update.id,
      update
    )
  }

  get(id: number): Observable<BusinessReviewSupport> {
    return this.http.get<BusinessReviewSupport>(
      this.baseUrl + "BusinessReviewSupports/" + id
    )
  }

  getBusinessReviewSupports(
    page?,
    itemsPerPage?,
    BusinessReviewSupportParams?
  ): Observable<PaginatedResult<BusinessReviewSupport[]>> {
    const paginatedResult: PaginatedResult<BusinessReviewSupport[]> =
      new PaginatedResult<BusinessReviewSupport[]>()

    let params = new HttpParams()

    if (page != null && itemsPerPage != null) {
      params = params.append("pageNumber", page)
      params = params.append("pageSize", itemsPerPage)
    }

    return this.http
      .get<BusinessReviewSupport[]>(this.baseUrl + "BusinessReviewSupports", {
        observe: "response",
        params,
      })
      .pipe(
        map((response) => {
          paginatedResult.result = response.body
          if (response.headers.get("Pagination") != null) {
            paginatedResult.pagination = JSON.parse(
              response.headers.get("Pagination")
            )
          }
          return paginatedResult
        })
      )
  }

  getByMfaId(id: number): Observable<BusinessReviewSupport[]> {
    return this.http.get<BusinessReviewSupport[]>(
      this.baseUrl +
        "MasterFranchiseAgreements/" +
        id +
        "/BusinessReviewSupports/"
    )
  }
}
