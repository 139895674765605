// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  apiKey: "AIzaSyDpXFAvBwWEk9vAP4A60GJ_oXIET2lDDJE",
  authDomain: "fastsigns-dev.firebaseapp.com",
  databaseURL: "https://fastsigns-dev.firebaseio.com",
  projectId: "fastsigns-dev",
  storageBucket: "fastsigns-dev.appspot.com",
  messagingSenderId: "818654046223",
  appId: "1:818654046223:web:09c1f48b347438832b1b7a",
  measurementId: "G-NPCRLPVE51",
  mapURL: "https://locations.propelledbrandsdev.com/",
};
