import { AlertifyService } from './../_services/alertify.service';
import { Observable ,  of } from 'rxjs';
import { ContactService } from './../_services/contact.service';
import { Router, Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Contact } from './../_models/Contact';
import { Injectable } from '@angular/core';
import { catchError } from '../../../node_modules/rxjs/operators';

@Injectable()
export class ContactsListResolver implements Resolve<Contact[]> {
    pageNumber = 1;
    pageSize = 25;

    constructor(private contactService: ContactService, private router: Router, private alertify: AlertifyService) {}

    resolve(route: ActivatedRouteSnapshot): Observable<Contact[]> {
        return this.contactService.getContacts(this.pageNumber, this.pageSize).pipe(
            catchError(error => {
                this.alertify.error(error.status + ': ' + error.statusText);
                this.router.navigate(['/dashboard']);
                return of(null);
            })
        );
    }
}
