import { Injectable } from '@angular/core';
declare let alertify: any;

@Injectable()
export class AlertifyService {
  constructor() {}

  confirm(title: string, message: string, okCallback: () => any, yesno?: boolean) {
    let dialog = alertify.confirm(message, function(e) {
      if (e) {
        okCallback()
      }
    }).setHeader(title)

    if (yesno) {
      dialog.set('labels', {ok:'Yes', cancel:'No'});
    }
  }

  alert(title: string, message: string) {
    alertify.alert(title, message)
  }

  success(message: string) {
    alertify.set('notifier', 'position', 'top-right');
    alertify.success(message);
  }

  error(message: string) {
    alertify.set('notifier', 'position', 'top-right');
    alertify.error(message);
  }

  warning(message: string) {
    alertify.set('notifier', 'position', 'top-right');
    alertify.warning(message);
  }

  message(message: string) {
    alertify.set('notifier', 'position', 'top-right');
    alertify.message(message);
  }
}
