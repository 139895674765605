import { AlertifyService } from './../_services/alertify.service';
import { Observable ,  of } from 'rxjs';
import { CenterService } from './../_services/center.service';
import { Router, Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { catchError } from '../../../node_modules/rxjs/operators';
import { CenterV2 } from 'app/_models/CenterV2';

@Injectable()
export class CentersEditResolver implements Resolve<CenterV2> {

    constructor(private centerService: CenterService, private router: Router, private alertify: AlertifyService) {}

    resolve(route: ActivatedRouteSnapshot): Observable<CenterV2> {
        return this.centerService.getCenterV2(route.params['id']).pipe(
            catchError(error => {
                this.alertify.error(error.status + ': ' + error.statusText);
                this.router.navigate(['/centers']);
                return of(null);
            })
        );
    }
}
