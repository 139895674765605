import { AlertifyService } from '../_services/alertify.service';
import { Observable ,  of } from 'rxjs';
import { ContactService } from '../_services/contact.service';
import { Router, Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { Contact } from 'app/_models/Contact';

@Injectable()
export class ContactsEditResolver implements Resolve<Contact> {

    constructor(private contactService: ContactService, private router: Router, private alertify: AlertifyService) {}

    resolve(route: ActivatedRouteSnapshot): Observable<Contact> {
        return this.contactService.get(route.params['id']).pipe(
            catchError(error => {
                this.alertify.error(error.status + ': ' + error.statusText);
                this.router.navigate(['/contacts']);
                return of(null);
            })
        );
    }
}
