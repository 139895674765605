import { Injectable } from "@angular/core";
import { EnviromentService } from "./enviroment.service";
import { HttpClient } from "@angular/common/http";
import { Award } from "app/_models/Award";
import { Observable } from "rxjs";

@Injectable()
export class AwardService {
  baseUrl = "";

  constructor(
    private http: HttpClient,
    private enviromentService: EnviromentService
  ) {
    this.baseUrl = enviromentService.getConfig().apiV3Url;
  }

  addAward(award: Award): Observable<number> {
    return this.http.post<number>(this.baseUrl + "awards", award);
  }

  deleteAward(id: number): Observable<boolean> {
    return this.http.delete<boolean>(this.baseUrl + "awards/" + id);
  }

  updateAward(award: Award): Observable<number> {
    return this.http.put<number>(this.baseUrl + "awards/" + award.id, award);
  }

  getAwardByCenterId(centerId: number): Observable<Award[]> {
    return this.http.get<Award[]>(
      this.baseUrl + "centers/" + centerId + "/awards"
    );
  }
}
