import { Injectable } from "@angular/core";
import { EnviromentService } from "./enviroment.service";
import { HttpClient } from "@angular/common/http";
import { Insurance } from "app/_models/Insurance";
import { Observable } from "rxjs";

@Injectable()
export class InsuranceService {
  baseUrl = "";

  constructor(
    private http: HttpClient,
    private enviromentService: EnviromentService
  ) {
    this.baseUrl = enviromentService.getConfig().apiV3Url;
  }

  add(add: Insurance): Observable<number> {
    return this.http.post<number>(this.baseUrl + "insurances", add);
  }

  delete(id: number): Observable<boolean> {
    return this.http.delete<boolean>(this.baseUrl + "insurances/" + id);
  }

  update(update: Insurance): Observable<number> {
    return this.http.put<number>(
      this.baseUrl + "insurances/" + update.id,
      update
    );
  }

  get(id: number): Observable<Insurance> {
    return this.http.get<Insurance>(this.baseUrl + "/insurances/" + id);
  }

  getByCenterId(centerId: number): Observable<Insurance[]> {
    return this.http.get<Insurance[]>(
      this.baseUrl + "centers/" + centerId + "/insurances"
    );
  }
}
