import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { tokenReference } from '@angular/compiler';

@Injectable()
export class AutocompleteService {

private financialsTypes = ['Monthly', 'Quarterly', 'Annual',  'Semi-Annual'];

private storeTypes = ['Center', 'Closed', 'Co-brand', 'Conversion', 'Master', 'Production', 'Satellite', 'Special', 'Training'];
private operational = ['0-12 months', '13-23 months', '24-47 months', '48+ months', 'Closed'];
private emediaCert = ['No', 'EMF', 'ST1', 'ST2', 'ST3', 'ST4'];
private phoneTypes = ['Home', 'Cell', 'Fax', 'Other'];
private addressTypes = ['Home', 'Work', 'Alternate', 'Other'];
private noteActivityTypes = ['Call Back', 'Call In', 'Call Out', 'Msg Rtrn', 'Msg Sent', 'Msg Filed'
, 'E-mail In', 'E-mail Out', 'Action', 'Appoint.', 'Sale', 'To-do', 'Event', 'Form', 'Other', '4G Coaching', 'Operations Visit', 'Call', 'Literature', 'Virtual Visit']

private genLiabLimitOptions = ['600000', '1000000', '1500000', '2000000', '3000000', '4000000', '5000000',
'6000000', '7000000', '8000000', '9000000', '10000000', '20000000'];

private nonOwnAutoLimOptions = ['300000', '500000', '600000', '1000000', '1500000', '2000000',
'3000000', '4000000', '5000000', '10000000', '20000000'];

private busInterLimitOptions = ['7500','10000','15000','25000','50000','100000','150000','500000'];

private empPracLLOptions = ['5000','10000','50000','60000','100000','250000','300000','500000','1000000'];

private docStatusOptions = [{'Id': 1, 'Status': 'Current'},
{'Id': 2 , 'Status': 'Void'}, {'Id': 3, 'Status': 'Expired'}, {'Id': 4, 'Status': 'Deleted'}];

constructor() { }

    public getFinancialTypes(): Observable<string[]>{
        return of(this.financialsTypes);
    }

    public getStoreTypes(): Observable<string[]>{
        return of(this.storeTypes);
    }

    public getOperationalOptions(): Observable<string[]>{
        return of(this.operational);
    }

    public getEmediaCertOptions(): Observable<string[]>{
        return of(this.emediaCert);
    }

    public getPhoneTypes(): Observable<string[]>{
        return of(this.phoneTypes);
    }

    public getAddressTypes(): Observable<string[]>{
        return of(this.addressTypes);
    }

    public getGenLiabLimitOptions(): Observable<string[]>{
        return of(this.genLiabLimitOptions);
    }

    public getNonOwnAutoLimOptions(): Observable<string[]>{
        return of(this.nonOwnAutoLimOptions);
    }

    public getBusInterLimitOptions(): Observable<string[]>{
        return of(this.busInterLimitOptions);
    }

    public getEmpPracLLOptions(): Observable<string[]>{
        return of(this.empPracLLOptions);
    }

    public getDocStatusOptions(): Observable<any[]> {
        return of(this.docStatusOptions);
    }

    public getNoteActivityTypes(): Observable<string[]>{
        return of(this.noteActivityTypes);
    }


}
