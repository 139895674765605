import { Injectable } from '@angular/core';

@Injectable()
export class PrintService {

constructor() { }

print(html: string, title: string) {
    let popupWindow;
    popupWindow = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWindow.document.open();
    popupWindow.document.write(`
      <html>
        <head>
        </head>
             ${title}
             <hr style="border-top: dotted 1px;" />
            <body onload="window.print();window.close()">${html}</body>
      </html>`
    );
    popupWindow.document.close();
  }

}
