import { MasterFranchiseAgreement } from "./../../_models/mfa/MasterFranchiseAgreement"
import { EnviromentService } from "./../enviroment.service"
import { Injectable } from "@angular/core"
import { HttpClient, HttpParams } from "@angular/common/http"
import { Observable } from "rxjs"
import { PaginatedResult } from "app/_models/Pagination"
import { map } from "rxjs/operators"

@Injectable()
export class MasterFranchiseAgreementService {
  baseUrl = ""

  constructor(
    private http: HttpClient,
    private enviromentService: EnviromentService
  ) {
    this.baseUrl = enviromentService.getConfig().apiMfa
  }

  add(add: MasterFranchiseAgreement): Observable<number> {
    return this.http.post<number>(
      this.baseUrl + "masterFranchiseAgreements",
      add
    )
  }

  delete(id: number): Observable<boolean> {
    return this.http.delete<boolean>(
      this.baseUrl + "masterFranchiseAgreements/" + id
    )
  }

  update(update: MasterFranchiseAgreement): Observable<number> {
    return this.http.put<number>(
      this.baseUrl + "masterFranchiseAgreements/" + update.id,
      update
    )
  }

  get(id: number): Observable<MasterFranchiseAgreement> {
    return this.http.get<MasterFranchiseAgreement>(
      this.baseUrl + "masterFranchiseAgreements/" + id
    )
  }

  getMasterFranchiseAgreements(
    page?,
    itemsPerPage?,
    masterFranchiseAgreementParams?
  ): Observable<PaginatedResult<MasterFranchiseAgreement[]>> {
    const paginatedResult: PaginatedResult<MasterFranchiseAgreement[]> =
      new PaginatedResult<MasterFranchiseAgreement[]>()

    let params = new HttpParams()

    if (page != null && itemsPerPage != null) {
      params = params.append("pageNumber", page)
      params = params.append("pageSize", itemsPerPage)
    }

    if (
      masterFranchiseAgreementParams != null &&
      masterFranchiseAgreementParams.Company != null &&
      masterFranchiseAgreementParams.Company.length > 0
    ) {
      params = params.append("Company", masterFranchiseAgreementParams.Company)
    }
    if (
      masterFranchiseAgreementParams != null &&
      masterFranchiseAgreementParams.ContactName != null &&
      masterFranchiseAgreementParams.ContactName.length > 0
    ) {
      params = params.append(
        "ContactName",
        masterFranchiseAgreementParams.ContactName
      )
    }
    if (
      masterFranchiseAgreementParams != null &&
      masterFranchiseAgreementParams.GroupAlias != null &&
      masterFranchiseAgreementParams.GroupAlias.length > 0
    ) {
      params = params.append(
        "GroupAlias",
        masterFranchiseAgreementParams.GroupAlias
      )
    }

    console.log(masterFranchiseAgreementParams)

    return this.http
      .get<MasterFranchiseAgreement[]>(
        this.baseUrl + "masterFranchiseAgreements",
        { observe: "response", params }
      )
      .pipe(
        map((response) => {
          paginatedResult.result = response.body
          if (response.headers.get("Pagination") != null) {
            paginatedResult.pagination = JSON.parse(
              response.headers.get("Pagination")
            )
          }
          return paginatedResult
        })
      )
  }
}
