import { Injectable } from "@angular/core";
import { EnviromentService } from "./enviroment.service";
import { HttpClient } from "@angular/common/http";
import { Agreement } from "app/_models/Agreement";
import { Observable } from "rxjs";

@Injectable()
export class AgreementsService {
  baseUrl = "";

  constructor(
    private http: HttpClient,
    private enviromentService: EnviromentService
  ) {
    this.baseUrl = enviromentService.getConfig().apiV3Url;
  }

  add(add: Agreement): Observable<number> {
    return this.http.post<number>(this.baseUrl + "agreements", add);
  }

  delete(id: number): Observable<boolean> {
    return this.http.delete<boolean>(this.baseUrl + "agreements/" + id);
  }

  update(update: Agreement): Observable<number> {
    return this.http.put<number>(
      this.baseUrl + "agreements/" + update.id,
      update
    );
  }

  get(id: number): Observable<Agreement> {
    return this.http.get<Agreement>(this.baseUrl + "/agreements/" + id);
  }

  getByCenterId(centerId: number): Observable<Agreement[]> {
    return this.http.get<Agreement[]>(
      this.baseUrl + "centers/" + centerId + "/agreements"
    );
  }
}
