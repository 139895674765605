import { AgreementsExpirationReport } from "./../_models/AgreementsExpirationReport";
import { IComplianceReport } from "./../_models/IComplianceReport";
import { IInsuranceReport } from "./../_models/IInsuranceReport";
import { EnviromentService } from "./enviroment.service";
import { Observable } from "rxjs";
import { PaginatedResult } from "../_models/Pagination";
import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { map } from "rxjs/operators";

@Injectable()
export class ReportService {
  baseUrl = "";

  constructor(
    private http: HttpClient,
    private enviromentService: EnviromentService
  ) {
    this.baseUrl = enviromentService.getConfig().apiV3Url;
  }

  GetInsurance(
    page?,
    itemsPerPage?,
    insuranceParams?
  ): Observable<PaginatedResult<IInsuranceReport[]>> {
    const paginatedResult: PaginatedResult<IInsuranceReport[]> =
      new PaginatedResult<IInsuranceReport[]>();
    let params = new HttpParams();
    if (page != null && itemsPerPage != null) {
      params = params.append("pageNumber", page);
      params = params.append("pageSize", itemsPerPage);
    }
    if (
      insuranceParams != null &&
      insuranceParams.Company != null &&
      insuranceParams.Company.length > 0
    ) {
      params = params.append("Company", insuranceParams.Company);
    }
    if (
      insuranceParams != null &&
      insuranceParams.CenterNumber != null &&
      insuranceParams.CenterNumber.length > 0
    ) {
      params = params.append(
        "CenterNumber",
        insuranceParams.CenterNumber.trim()
      );
    }
    if (
      insuranceParams != null &&
      insuranceParams.ExpiresIn != null &&
      insuranceParams.ExpiresIn.length > 0
    ) {
      params = params.append("ExpiresIn", insuranceParams.ExpiresIn);
    }
    if (
      insuranceParams != null &&
      insuranceParams.Expired != null &&
      insuranceParams.Expired.length > 0
    ) {
      params = params.append("Expired", insuranceParams.Expired);
    }

    if (
      insuranceParams != null &&
      insuranceParams.ShowAll != null &&
      insuranceParams.ShowAll.length > 0
    ) {
      params = params.append("ShowAll", insuranceParams.ShowAll);
    }

    if (
      insuranceParams != null &&
      insuranceParams.Export != null &&
      insuranceParams.Export.length > 0
    ) {
      params = params.append("Export", insuranceParams.Export);
    }

    return this.http
      .get<IInsuranceReport[]>(this.baseUrl + "insurances/Params", {
        observe: "response",
        params,
      })
      .pipe(
        map((response) => {
          paginatedResult.result = response.body;
          if (response.headers.get("Pagination") != null) {
            paginatedResult.pagination = JSON.parse(
              response.headers.get("Pagination")
            );
          }
          return paginatedResult;
        })
      );
  }

  GetAgreementsExpiration(
    page?,
    itemsPerPage?,
    AgreementsExpirationParams?
  ): Observable<PaginatedResult<AgreementsExpirationReport[]>> {
    const paginatedResult: PaginatedResult<AgreementsExpirationReport[]> =
      new PaginatedResult<AgreementsExpirationReport[]>();
    let params = new HttpParams();
    if (page != null && itemsPerPage != null) {
      params = params.append("pageNumber", page);
      params = params.append("pageSize", itemsPerPage);
    }

    if (
      AgreementsExpirationParams != null &&
      AgreementsExpirationParams.Export != null &&
      AgreementsExpirationParams.Export.length > 0
    ) {
      params = params.append("Export", AgreementsExpirationParams.Export);
    }
    if (
      AgreementsExpirationParams != null &&
      AgreementsExpirationParams.Contact != null &&
      AgreementsExpirationParams.Contact.length > 0
    ) {
      params = params.append("Contact", AgreementsExpirationParams.Contact);
    }
    if (
      AgreementsExpirationParams != null &&
      AgreementsExpirationParams.CenterNumber != null &&
      AgreementsExpirationParams.CenterNumber.length > 0
    ) {
      params = params.append(
        "CenterNumber",
        AgreementsExpirationParams.CenterNumber.trim()
      );
    }
    if (
      AgreementsExpirationParams != null &&
      AgreementsExpirationParams.Country != null &&
      AgreementsExpirationParams.Country.length > 0
    ) {
      params = params.append("Country", AgreementsExpirationParams.Country);
    }
    return this.http
      .get<AgreementsExpirationReport[]>(
        this.baseUrl + "Agreements/ExpirationReport",
        { observe: "response", params }
      )
      .pipe(
        map((response) => {
          paginatedResult.result = response.body;
          if (response.headers.get("Pagination") != null) {
            paginatedResult.pagination = JSON.parse(
              response.headers.get("Pagination")
            );
          }
          return paginatedResult;
        })
      );
  }

  getComplianceReport(): Observable<IComplianceReport[]> {
    return this.http.get<IComplianceReport[]>(
      this.baseUrl + "reports/compliance"
    );
  }

  getComplianceReportByCenter(
    centerNumber: string
  ): Observable<IComplianceReport[]> {
    return this.http.get<IComplianceReport[]>(
      this.baseUrl + "centers/" + centerNumber + "/reports/compliance"
    );
  }
}
