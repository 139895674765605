import { Attachment } from "./../_models/Attachment";
import { Financial } from "./../_models/Financial";
import { Contact } from "./../_models/Contact";
import { ICenterUpdate } from "./../_models/ICenterUpdate";
import { EnviromentService } from "./enviroment.service";
import { Observable } from "rxjs";
import { PaginatedResult } from "../_models/Pagination";
import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { map } from "rxjs/operators";
import { CenterV2 } from "app/_models/CenterV2";
import { Note } from "app/_models/Note";

@Injectable({
  providedIn: "root",
})
export class CenterService {
  baseUrl = "";
  constructor(
    private http: HttpClient,
    private enviromentService: EnviromentService
  ) {
    this.baseUrl = enviromentService.getConfig().apiV3Url;
  }

  getCenters(
    page?,
    itemsPerPage?,
    centerParams?
  ): Observable<PaginatedResult<CenterV2[]>> {
    const paginatedResult: PaginatedResult<CenterV2[]> = new PaginatedResult<
      CenterV2[]
    >();

    let params = new HttpParams();

    if (page != null && itemsPerPage != null) {
      params = params.append("pageNumber", page);
      params = params.append("pageSize", itemsPerPage);
    }

    if (centerParams != null && centerParams.GroupId != null) {
      params = params.append("GroupId", centerParams.GroupId);
    }

    if (centerParams != null && centerParams.StatusId != null) {
      params = params.append("StatusId", centerParams.StatusId);
    }

    if (
      centerParams != null &&
      centerParams.Company != null &&
      centerParams.Company.length > 0
    ) {
      params = params.append("Company", centerParams.Company);
    }

    if (
      centerParams != null &&
      centerParams.PhoneNumber != null &&
      centerParams.PhoneNumber.length > 0
    ) {
      params = params.append("PhoneNumber", centerParams.PhoneNumber);
    }

    if (
      centerParams != null &&
      centerParams.CenterNumber != null &&
      centerParams.CenterNumber.length > 0
    ) {
      params = params.append("CenterNumber", centerParams.CenterNumber.trim());
    }

    if (
      centerParams != null &&
      centerParams.centerId != null &&
      centerParams.centerId.length > 0
    ) {
      params = params.append("centerId", centerParams.centerId.trim());
    }

    if (
      centerParams != null &&
      centerParams.locationId != null &&
      centerParams.locationId.length > 0
    ) {
      params = params.append("locationId", centerParams.locationId.trim());
    }

    if (
      centerParams != null &&
      centerParams.WebNumber != null &&
      centerParams.WebNumber.length > 0
    ) {
      params = params.append("WebNumber", centerParams.WebNumber.trim());
    }

    if (
      centerParams != null &&
      centerParams.Operational != null &&
      centerParams.Operational.length > 0
    ) {
      if (centerParams.Operational === "48+ months") {
        centerParams.Operational = "48";
      }
      params = params.append("Operational", centerParams.Operational);
    }

    if (
      centerParams != null &&
      centerParams.City != null &&
      centerParams.City.length > 0
    ) {
      params = params.append("City", centerParams.City);
    }

    if (
      centerParams != null &&
      centerParams.State != null &&
      centerParams.State.length > 0
    ) {
      params = params.append("State", centerParams.State);
    }

    if (
      centerParams != null &&
      centerParams.Zip != null &&
      centerParams.Zip.length > 0
    ) {
      params = params.append("Zip", centerParams.Zip);
    }

    if (
      centerParams != null &&
      centerParams.Country != null &&
      centerParams.Country.length > 0
    ) {
      params = params.append("Country", centerParams.Country);
    }

    if (
      centerParams != null &&
      centerParams.VanityName != null &&
      centerParams.VanityName.length > 0
    ) {
      params = params.append("VanityName", centerParams.VanityName);
    }

    if (
      centerParams != null &&
      centerParams.Contact != null &&
      centerParams.Contact.length > 0
    ) {
      params = params.append("Contact", centerParams.Contact);
    }

    if (
      centerParams != null &&
      centerParams.Fmm != null &&
      centerParams.Fmm.length > 0
    ) {
      params = params.append("Fmm", centerParams.Fmm);
    }

    if (
      centerParams != null &&
      centerParams.Fbc != null &&
      centerParams.Fbc.length > 0
    ) {
      params = params.append("Fbc", centerParams.Fbc);
    }

    if (
      centerParams != null &&
      centerParams.ShowAll != null &&
      centerParams.ShowAll.length > 0
    ) {
      params = params.append("ShowAll", centerParams.ShowAll);
    }

    if (
      centerParams != null &&
      centerParams.Export != null &&
      centerParams.Export.length > 0
    ) {
      params = params.append("Export", centerParams.Export);
    }

    if (
      centerParams != null &&
      centerParams.IsExcludedFromSalesReport != null &&
      centerParams.IsExcludedFromSalesReport.length > 0
    ) {
      params = params.append(
        "IsExcludedFromSalesReport",
        centerParams.IsExcludedFromSalesReport
      );
    }

    return this.http
      .get<CenterV2[]>(this.baseUrl + "centers", {
        observe: "response",
        params,
      })
      .pipe(
        map((response) => {
          paginatedResult.result = response.body;
          if (response.headers.get("Pagination") != null) {
            paginatedResult.pagination = JSON.parse(
              response.headers.get("Pagination")
            );
          }
          return paginatedResult;
        })
      );
  }

  getAllCenters(): Observable<CenterV2[]> {
    return this.http.get<CenterV2[]>(this.baseUrl + "centers");
  }

  add(center: ICenterUpdate): Observable<number> {
    return this.http.post<number>(this.baseUrl + "centers", center);
  }

  update(id, center: ICenterUpdate) {
    return this.http.put(this.baseUrl + "centers/" + id, center);
  }

  getCenterV2(id): Observable<CenterV2> {
    return this.http.get<CenterV2>(this.baseUrl + "centers/" + id);
  }

  getNotes(
    id,
    notesParams?,
    page?,
    itemsPerPage?
  ): Observable<PaginatedResult<Note[]>> {
    const paginatedResult: PaginatedResult<Note[]> = new PaginatedResult<
      Note[]
    >();
    let params = new HttpParams();
    if (page != null && itemsPerPage != null) {
      params = params.append("pageNumber", page);
      params = params.append("pageSize", itemsPerPage);
    }
    if (
      notesParams != null &&
      notesParams.Notes != null &&
      notesParams.Notes.length > 0
    ) {
      params = params.append("Notes", notesParams.Notes);
    }
    if (
      notesParams != null &&
      notesParams.Contact != null &&
      notesParams.Contact.length > 0
    ) {
      params = params.append("Contact", notesParams.Contact);
    }
    if (
      notesParams != null &&
      notesParams.Activity != null &&
      notesParams.Activity.length > 0
    ) {
      params = params.append("Activity", notesParams.Activity);
    }
    if (
      notesParams != null &&
      notesParams.CreatedBy != null &&
      notesParams.CreatedBy.length > 0
    ) {
      params = params.append("CreatedBy", notesParams.CreatedBy);
    }
    return this.http
      .get<Note[]>(this.baseUrl + "centers/" + id + "/notes", {
        observe: "response",
        params,
      })
      .pipe(
        map((response) => {
          paginatedResult.result = response.body;
          if (response.headers.get("Pagination") != null) {
            paginatedResult.pagination = JSON.parse(
              response.headers.get("Pagination")
            );
          }
          return paginatedResult;
        })
      );
  }

  getNoteReminders(
    notesParams?,
    page?,
    itemsPerPage?
  ): Observable<PaginatedResult<Note[]>> {
    const paginatedResult: PaginatedResult<Note[]> = new PaginatedResult<
      Note[]
    >();
    let params = new HttpParams();

    params = params.append("RemindersOnly", "true");

    if (page != null && itemsPerPage != null) {
      params = params.append("pageNumber", page);
      params = params.append("pageSize", itemsPerPage);
    }
    if (
      notesParams != null &&
      notesParams.Notes != null &&
      notesParams.Notes.length > 0
    ) {
      params = params.append("Notes", notesParams.Notes);
    }
    if (
      notesParams != null &&
      notesParams.Contact != null &&
      notesParams.Contact.length > 0
    ) {
      params = params.append("Contact", notesParams.Contact);
    }
    if (
      notesParams != null &&
      notesParams.Activity != null &&
      notesParams.Activity.length > 0
    ) {
      params = params.append("Activity", notesParams.Activity);
    }
    if (
      notesParams != null &&
      notesParams.CreatedBy != null &&
      notesParams.CreatedBy.length > 0
    ) {
      params = params.append("CreatedBy", notesParams.CreatedBy);
    }
    return this.http
      .get<Note[]>(this.baseUrl + "notes", { observe: "response", params })
      .pipe(
        map((response) => {
          paginatedResult.result = response.body;
          if (response.headers.get("Pagination") != null) {
            paginatedResult.pagination = JSON.parse(
              response.headers.get("Pagination")
            );
          }
          return paginatedResult;
        })
      );
  }

  deleteNote(centerId: number, id: number): Observable<boolean> {
    return this.http.delete<boolean>(
      this.baseUrl + "centers/" + centerId + "/notes/" + id
    );
  }

  updateNote(centerId: number, id: number, note: Note): Observable<number> {
    return this.http.put<number>(
      this.baseUrl + "centers/" + centerId + "/notes/" + id,
      note
    );
  }

  addNote(centerId: number, add: Note): Observable<number> {
    return this.http.post<number>(
      this.baseUrl + "centers/" + centerId + "/notes/",
      add
    );
  }

  getNewCenters(): Observable<CenterV2[]> {
    return this.http.get<CenterV2[]>(
      this.baseUrl + "centers?ShowAll=true&ComingSoonOnly=true&PageSize=40"
    );
  }

  getNoteAttachments(id: number): Observable<Attachment[]> {
    return this.http.get<Attachment[]>(
      this.baseUrl + "notes/" + id + "/attachments"
    );
  }

  downloadAttachment(id: number, fileName: string): Observable<Blob> {
    return this.http.get<Blob>(
      this.baseUrl + "notes/" + id + "/attachments/" + fileName,
      { responseType: "blob" as "json" }
    );
  }

  uploadAttachment(id: number, file: FormData): Observable<string> {
    return this.http.post<string>(
      this.baseUrl + "notes/" + id + "/attachments",
      file,
      { responseType: "text" as "json" }
    );
  }

  deleteAttachments(id: number, fileName: string): Observable<boolean> {
    return this.http.delete<boolean>(
      this.baseUrl + "notes/" + id + "/attachments/" + fileName
    );
  }

  getCenterByCenterNumberV2(centerNumber): Observable<CenterV2> {
    return this.http.get<CenterV2>(
      this.baseUrl + "centers/centerNumber/" + centerNumber
    );
  }

  salesReports(centerId: number, excluded: string): Observable<number> {
    return this.http.post<number>(
      this.baseUrl +
        "centers/" +
        centerId +
        "/salesreports?excluded=" +
        excluded,
      null
    );
  }
}
