import { Injectable } from "@angular/core";
import { EnviromentService } from "./enviroment.service";
import { HttpClient, HttpParams } from "@angular/common/http";
import { map } from "rxjs/operators";
import { Observable } from "rxjs";
import { PaginatedResult } from "app/_models/Pagination";
import { NoteGroup } from "app/_models/NoteGroup";

@Injectable()
export class NoteGroupsService {
  baseUrl = "";

  constructor(
    private http: HttpClient,
    private enviromentService: EnviromentService
  ) {
    this.baseUrl = enviromentService.getConfig().apiV3Url;
  }

  getGroups(page?, itemsPerPage?): Observable<PaginatedResult<NoteGroup[]>> {
    const paginatedResult: PaginatedResult<NoteGroup[]> = new PaginatedResult<
      NoteGroup[]
    >();

    let params = new HttpParams();

    if (page != null && itemsPerPage != null) {
      params = params.append("pageNumber", page);
      params = params.append("pageSize", itemsPerPage);
    }

    return this.http
      .get<NoteGroup[]>(this.baseUrl + "NoteGroups", {
        observe: "response",
        params,
      })
      .pipe(
        map((response) => {
          paginatedResult.result = response.body;
          if (response.headers.get("Pagination") != null) {
            paginatedResult.pagination = JSON.parse(
              response.headers.get("Pagination")
            );
          }
          paginatedResult.result = paginatedResult.result.sort(function (a, b) {
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          });
          return paginatedResult;
        })
      );
  }
}
