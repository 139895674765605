import { Injectable } from "@angular/core";
import { EnviromentService } from "./enviroment.service";
import { HttpClient } from "@angular/common/http";
import { Financial } from "app/_models/Financial";
import { Observable } from "rxjs";
import { AddFinancial } from "app/_models/AddFinancial.modal";

@Injectable()
export class FinancialService {
  baseUrl = "";

  constructor(
    private http: HttpClient,
    private enviromentService: EnviromentService
  ) {
    this.baseUrl = enviromentService.getConfig().apiV3Url;
  }

  add(financial: AddFinancial): Observable<number> {
    return this.http.post<number>(this.baseUrl + "financials", financial);
  }

  delete(id: number): Observable<boolean> {
    return this.http.delete<boolean>(this.baseUrl + "financials/" + id);
  }

  update(update: Financial): Observable<number> {
    return this.http.put<number>(
      this.baseUrl + "financials/" + update.id,
      update
    );
  }

  get(id: number): Observable<Financial> {
    return this.http.get<Financial>(this.baseUrl + "/financials/" + id);
  }

  getByCenterId(centerId: number): Observable<Financial[]> {
    return this.http.get<Financial[]>(
      this.baseUrl + "centers/" + centerId + "/financials"
    );
  }
}
